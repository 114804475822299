export default {
  computed: {
    totalPages() {
        return this.$store.getters[`${this.$data.storePath}/totalPages`];
      },
      page: {
        get() {
          return this.$store.getters[`${this.$data.storePath}/currentPage`];
        },
        set(val) {
          return this.$store.commit(`${this.$data.storePath}/SET_CURRENT_PAGE`, val - 1);
        },
      },
    tags() {
        let allTags = this.$store.getters[`${this.$data.tagPath}/tags`];
        return allTags?.filter((tag) => {
          return tag.tagType === this.$data.tagType;
        });
      },
  },
  created() {
    this.initialize();
    if(this.$data.tagType === 'Deduction') {
        this.getAllTags();
        this.fetchEmployeesIdsNames();
    }
  },
  methods:{
    getAllTags() {
      this.$store.dispatch("additions/fetchAllTags");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
