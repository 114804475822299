<template>
  <div>
    <template>
      <v-snackbar :timeout="5000" :value="true" top :color="isError ? 'error' : 'success'" center v-model="snackBar"
        outlined elevation="24" text>
        {{ snackBarText }}
        <template v-slot:action="{ attrs }">
          <v-btn data-cy="closeBtn" :color="isError ? 'error' : 'success'" text v-bind="attrs"
            @click="snackBar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <div v-if="isLoading">
      <spinner></spinner>
    </div>
    <template>
      <h2 class="text-amalitech">Definitions</h2>
      <div class="text-right">
        <v-btn data-cy="definitions-add" color="primary" dark class="mb-10 text-right" @click="dialog = true">
          ADD NEW
        </v-btn>
      </div>
      <v-dialog data-cy="definitions-dialog" v-model="dialog" max-width="400px" persistent>
        <v-card data-cy="definitions-card">
          <v-card-text class="text-center pt-5">
            <p class="text-h6 pt-5 font-weight-bold">{{ formTitle }}</p>
          </v-card-text>
          <v-form class="mb-0 px-5" ref="form">
            <p class="mb-0">Select Definition Type</p>

            <v-col cols="12" sm="12" md="12">
              <v-select data-cy="definitions-select" dense outlined :items="definitionsType"
                item-value="editedItem.tagType" item-text="editedItem.tagType" v-model="editedItem.tagType"
                :rules="[rules.required]">
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field data-cy="definitions-name" :rules="[rules.required]" dense outlined v-model="editedItem.tag"
                label="Name"></v-text-field>
            </v-col>
          </v-form>

          <v-card-actions align-content="center">
            <v-spacer></v-spacer>
            <v-btn data-cy="cancelBtn" color="secondary darken-1" @click="close">
              Cancel
            </v-btn>

            <v-btn data-cy="definitions-save" color="primary darken-1" @click="save">
              {{ btnTitle }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog data-cy="definitions-delete" v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions data-cy="definitions-delete-actions" align-content="center">
            <v-spacer></v-spacer>
            <v-btn data-cy="cancelBtn" color="secondary darken-1" @click="closeDelete">Cancel</v-btn>
            <v-btn data-cy="okBtn" color="primary darken-1" @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-simple-table data-cy="definitions-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col" class="text-left text-uppercase">Name</th>
              <th scope="col" class="text-left text-uppercase">
                Definition Type
              </th>
              <th scope="col" class="text-right text-uppercase">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in definitions" :key="item.tagId">
              <td>{{ item.tag }}</td>
              <td>{{ item.tagType }}</td>
              <td class="text-right text-uppercase">
                <v-icon data-cy="definitions-edit" color="primary" dense size="24px" class="mr-2"
                  @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon data-cy="deleteBtn" color="primary" size="24px" dense @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center pt-2">
        <v-pagination data-cy="deduction-pagination" v-model="page" :length="totalPages" @input="paginatePage"
          circle></v-pagination>
      </div>
    </template>
  </div>
</template>

<script>
import methMixin from "../services/mixins/paginateMethodMixin";
import pageMixin from "../services/mixins/pageMixin";
export default {
  mixins: [methMixin, pageMixin],
  data: () => ({
    isError: true,
    snackBar: false,
    snackBarText: "",
    isLoading: false,
    dialog: false,
    dialogDelete: false,
    editMode: false,
    storePath: "definitions",
    tagPath: "additions",
    sourcePath: "definitions",
    actionType: "fetchDefinitions",
    editedItem: {
      tagId: null,
      tag: "",
      tagType: "",
    },
    editedIndex: -1,
    definitionsType: ["Allowance", "Bonus", "Deduction"],
    defaultItem: {
      id: null,
      tag: "",
      tagType: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    selectedItems: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Definition" : "Edit Definition";
    },
    btnTitle() {
      return this.editedIndex === -1 ? "Add" : "Update";
    },
    definitions() {
      return this.$store.getters["definitions/allDefinitions"];
    },
  },
  methods: {
    async initialize() {
      this.isLoading = true;
      let page = this.page;
      await this.$store.dispatch("definitions/fetchDefinitions", page);
      this.isLoading = false;
    },

    editItem(item) {
      this.editedIndex = this.definitions.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.selectedItems = item.tagId;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.isLoading = true;
      this.closeDelete();
      await this.$store.dispatch(
        "definitions/deleteDefinitions",
        this.selectedItems
      );

      await this.initialize();
      this.isError = false;
      this.snackBar = true;
      this.snackBarText = " Definition Deleted Successfully";
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.isLoading = true;
          this.close();
          this.editedItem = {
            ...this.definitions[this.editedIndex],
            ...this.editedItem,
          };
          await this.$store.dispatch(
            "definitions/editDefinitions",
            this.editedItem
          );
          this.isError = false;
          await this.initialize();
          this.snackBar = true;
          this.snackBarText = "Definition Edited Successfully";
        } else {
          switch (true) {
            case this.editedItem.tag === "" && this.editedItem.tagType === "":
              this.isError = true;
              this.snackBar = true;
              this.snackBarText = "Enter definition type and name";
              break;
            case this.editedItem.tag !== "" || this.editedItem.tagType !== "":
              this.isLoading = true;
              this.close();
              this.$store.dispatch(
                "definitions/createDefinitions",
                this.editedItem
              );
              this.isError = false;
              await this.initialize();
              this.snackBar = true;
              this.snackBarText = "Definition Created Successfully";
              break;
            default:
              break;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.col-12,
.col.sm-12,
.col-md-12 {
  padding: 0 !important;
}

h2 {
  margin-top: -1rem;
  font-size: 1.8rem;
  color: #cf4f1f;
  font-weight: 500;
  letter-spacing: 0.1rem;
}
</style>
